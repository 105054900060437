import React from "react";
import "./footer.scss";
const Footer = () => {
  return (
    <section id="footer" className="footer">
      <div className="container">
        <div className="row">
          {/* CONTENT FOR MOBILE NO */}
          <div className="col-md-4 col-lg-4 contact-box pt-1 d-m-block d-lg-flex d-flex">
            <div className="contact-box__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-phone-call"
                viewBox="0 0 24 24"
                stroke-width="1"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                <path d="M15 7a2 2 0 0 1 2 2" />
                <path d="M15 3a6 6 0 0 1 6 6" />
              </svg>
            </div>
            <div className="contact-box__info">
              <a href="tel:+919415984124" className="contact-box__info--title">
                +91 9415984124
              </a>
              <p className="contact-box__info--subtitle">
                Monday-Saturday 10am-10pm
              </p>
            </div>
          </div>
          {/* CONTENT FOR EMAIL */}
          <div className="col-md-4 col-lg-4 contact-box pt-1 d-m-block d-lg-flex d-flex">
            <div className="contact-box__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-mail-opened"
                viewBox="0 0 24 24"
                stroke-width="1"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 9l9 6l9 -6l-9 -6l-9 6" />
                <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />
                <path d="M3 19l6 -6" />
                <path d="M15 13l6 6" />
              </svg>
            </div>
            <div className="contact-box__info">
              <a
                href="mailto: jatanshreehandicraft@yahoo.com"
                className="contact-box__info--title"
              >
                info@meenakari.com
              </a>
              <p className="contact-box__info--subtitle">Online Support</p>
            </div>
          </div>
          {/* CONTENT FOR LOCATION */}
          <div className="col-md-4 col-lg-4 contact-box pt-1 d-m-block d-lg-flex d-flex">
            <div className="contact-box__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-map-2"
                viewBox="0 0 24 24"
                stroke-width="1"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 18.5l-3 -1.5l-6 3v-13l6 -3l6 3l6 -3v7.5" />
                <path d="M9 4v13" />
                <path d="M15 7v5.5" />
                <path d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z" />
                <path d="M19 18v.01" />
              </svg>
            </div>
            <div className="contact-box__info">
              <a
                href="https://maps.app.goo.gl/BcQRnCr6mg5twv8MA"
                className="contact-box__info--title"
              >
                A1/22-A, Gayghat,
              </a>
              <p className="contact-box__info--subtitle">Varanasi 221001.</p>
            </div>
          </div>
        </div>
      </div>
      {/* SOCILA MEDIA SECTION */}
      <div className="footer-sm">
        <div className="container">
          <div className="row py-4 text-center text-white">
            <div className="col-lg-5 col-md-6 mb-4 mb-md-0">
              Connect with us on social media
            </div>
            <div className="col-lg-7 col-md-6">
              <a href="#home">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://twitter.com/Rohankmr40Sumit">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/banaras_gulabi_meenakari">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/watch?v=EGUvryu-Msw&feature=youtu.be">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* START MAP LOCTION */}
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-12 map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3606.548903200964!2d83.01929517538578!3d25.319355677632544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE5JzA5LjciTiA4M8KwMDEnMTguNyJF!5e0!3m2!1sen!2sin!4v1733926950252!5m2!1sen!2sin"
              width="800"
              height="600"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="container-fluid"
              title="Google Map Location"
            />
          </div>
        </div>
      </div>
      <div className="footer-bottom pt-5 pb-5">
        <div className="container">
          <div className="row text-center text-white">
            <div className="col-12">
              <div className="footer-bottom__copyright">
                &copy; Copyright {new Date().getFullYear()}{" "}
                <a href="https://banarasgulabimeenakariartist.com">
                  Banaras Gulabi Meenakari Artist
                </a>{" "}
                | Created by{" "}
                <a href="https://candid-kangaroo-82e1fe.netlify.app">
                  SKY Web Solutions - Contact-7979097553
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
